import React, { memo } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "./seo";
import Navbar from "./navbar";
import Footer from "./footer";

import Logo from "../data/assets/studioemi.svg";
import "../styles/layout.scss";

const Layout = ({ title, children }) => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuItems {
              to
              value
            }
            companyInfo {
              email
              partitaIva
            }
          }
        }
      }
    `
  );

  return (
    <>
      <SEO title={title} />
      <Navbar
        brand={<img src={Logo} alt="StudioEmi" />}
        items={siteMetadata.menuItems.map(({ to, value }) => ({
          to,
          value
        }))}
      />
      {children}
      <Footer
        brand={<img src={Logo} width="200" alt="StudioEmi" />}
        email={siteMetadata.companyInfo.email}
        partitaIva={siteMetadata.companyInfo.partitaIva}
      />
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default memo(Layout);
