import React, { memo, useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Hamburger from "./hamburger";
import styles from "../../styles/navbar.module.scss";

const Navbar = ({ brand, items, className }) => {
  const [isNavbarActive, setIsNavbarActive] = useState(false);

  const menuItems = items.map(({ to, value }) => (
    <Link className="navbar-item" to={to} key={value}>
      {value}
    </Link>
  ));

  const activeClass = isNavbarActive ? "is-active" : "";

  return (
    <nav className={`navbar is-spaced ${className}`} role="navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          {brand}
        </Link>
        <Hamburger
          className={activeClass}
          expanded={isNavbarActive}
          onClick={() => setIsNavbarActive(!isNavbarActive)}
        />
      </div>

      <div
        className={`navbar-menu ${styles.menu} ${activeClass}`}
        role="navigation"
        aria-label="main navigation"
        aria-expanded={activeClass ? "true" : "false"}
      >
        <div className="navbar-end">{menuItems}</div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  brand: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  className: PropTypes.string
};

Navbar.defaultProps = {
  className: ""
};

export default memo(Navbar);
