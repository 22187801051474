import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "../../styles/navbar.module.scss";

const Hamburger = ({ onClick, expanded, className }) => (
  <button
    type="button"
    className={`navbar-burger ${styles.hamburger} ${className}`}
    aria-label="menu"
    onClick={onClick}
    aria-expanded={expanded}
  >
    <span aria-hidden="true" />
    <span aria-hidden="true" />
    <span aria-hidden="true" />
  </button>
);

Hamburger.propTypes = {
  onClick: PropTypes.func,
  expanded: PropTypes.bool,
  className: PropTypes.string
};

Hamburger.defaultProps = {
  onClick: () => {},
  expanded: false,
  className: ""
};

export default memo(Hamburger);
