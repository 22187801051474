import React, { memo } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";

import Logo from "../data/assets/studioemi.svg";

const SEO = ({ title }) => {
  const {
    description,
    site: { buildTime, siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        description: markdownRemark(
          frontmatter: { key: { eq: "content" }, title: { eq: "Description" } }
        ) {
          excerpt
        }
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            siteUrl
            title
            themeColor
            author
            companyInfo {
              name
              founder
              telephone
              fax
              email
              address {
                street
                locality
                region
                postalCode
                country
              }
            }
          }
        }
      }
    `
  );

  const { companyInfo } = siteMetadata;
  const desc = description.excerpt;

  const webPageStructData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "@id": `${siteMetadata.siteUrl}#webpage`,
    url: siteMetadata.siteUrl,
    inLanguage: "it",
    mainEntityOfPage: siteMetadata.siteUrl,
    description: desc,
    name: title,
    author: {
      "@type": "Person",
      name: siteMetadata.author
    },
    copyrightHolder: {
      "@type": "Organization",
      name: companyInfo.name
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Person",
      name: siteMetadata.author
    },
    publisher: {
      "@type": "Organization",
      name: companyInfo.name
    },
    datePublished: "2019-05-12T10:30:00+01:00",
    dateModified: buildTime
  };

  const companyStructData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": `${siteMetadata.siteUrl}#organization`,
    name: companyInfo.name,
    url: siteMetadata.siteUrl,
    logo: Logo,
    image: Logo,
    founder: companyInfo.founder,
    telephone: companyInfo.telephone,
    address: {
      "@type": "PostalAddress",
      streetAddress: companyInfo.address.street,
      addressLocality: companyInfo.address.locality,
      addressRegion: companyInfo.address.region,
      postalCode: companyInfo.address.postalCode,
      addressCountry: companyInfo.address.country
    },
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: companyInfo.telephone,
        email: companyInfo.email,
        faxNumber: companyInfo.fax,
        contactType: "customer service"
      }
    ]
  };

  const listingStructData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": `${siteMetadata.siteUrl}#localbusiness`,
    name: companyInfo.name,
    image: Logo,
    address: {
      "@type": "PostalAddress",
      streetAddress: companyInfo.address.street,
      addressLocality: companyInfo.address.locality,
      addressRegion: companyInfo.address.region,
      postalCode: companyInfo.address.postalCode,
      addressCountry: companyInfo.address.country
    },
    url: siteMetadata.siteUrl,
    telephone: companyInfo.telephone,
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: companyInfo.opens,
        closes: companyInfo.closes
      }
    ]
  };

  return (
    <Helmet title={title} titleTemplate={`%s | ${siteMetadata.title}`}>
      <html lang="it" />
      <meta name="description" content={desc} />
      <meta name="theme-color" content={siteMetadata.themeColor} />
      <meta name="image" content={Logo} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={desc} />
      <meta name="og:type" content="website" />
      <meta name="og:image" content={Logo} />
      <script type="application/ld+json">
        {JSON.stringify(webPageStructData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(companyStructData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(listingStructData)}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string
};

SEO.defaultProps = {
  title: ""
};

export default memo(SEO);
