import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "../../styles/footer.module.scss";

const Footer = ({ brand, email, partitaIva, className }) => (
  <div className={`footer ${styles.footer} ${className}`}>
    <div className="container">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p>{brand}</p>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            Copyright © {new Date().getFullYear()} StudioEmi
          </div>
          <div className="level-item">Partita Iva {partitaIva}</div>
          <div className="level-item">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      </nav>
    </div>
  </div>
);

Footer.propTypes = {
  brand: PropTypes.node,
  email: PropTypes.string,
  partitaIva: PropTypes.string,
  className: PropTypes.string
};

Footer.defaultProps = {
  brand: "",
  email: "",
  partitaIva: "",
  className: ""
};

export default memo(Footer);
